import React, {Component} from 'react';
import ProblemSensorChart from "./problem_sensor_chart";
import GeneralSensorChart from "./general_sensor_chart";
import CheckLink from "./check_link";
import CheckNoteLink from "./check_note_link";

class AlertCountLinks extends Component {

  constructor(props) {
    super(props);
  }

  getOrCreateAlertSoundElement() {
    let audio = document.getElementById('alert-sound');
    if (audio == null) {
      audio = document.createElement('audio');
      audio.id = 'alert-sound';
      audio.src = 'audio/alert_sound.mp3';
      audio.type = 'audio/mpeg';
      $$('body')[0].append(audio);
    } else {
      audio.currentTime = 0;
    }
    return (audio);
  }

  fetchData() {
    if (this.fetchDataTimeout) { clearTimeout(this.fetchDataTimeout) }

    fetch('/alert_count', {
      credentials: "same-origin"
    })
      .then(response => {
        if (!response.ok) { throw Error(response.statusText); }
        return response.json()
      })
      .catch(() => {})
      .then(data => this.setState(data))
      .then(() => {
        this.fetchDataTimeout = setTimeout(this.fetchData.bind(this), this.props.refresh_seconds * 1000)
      })
  }

  componentDidUpdate(prevProps, prevState) {
    // TODO: Respect User Profile Settings
    if (prevProps.global_most_recent_alert_time !== this.props.global_most_recent_alert_time) {
      let audio = this.getOrCreateAlertSoundElement();
      audio.play()
    }
  }

  componentDidMount() {
    this.fetchDataTimeout = setTimeout(this.fetchData.bind(this), this.props.refresh_seconds * 1000)
  }

  componentWillUnmount() {
    if (this.fetchDataTimeout) { clearTimeout(this.fetchDataTimeout) }
  }

  render() {
    const {
      check_link,
      global_check_count,
      check_note_link,
      global_check_note_count,
      global_alert_count_name,
      global_alert_low_count,
      global_alert_high_count,
      global_active_count,
      global_inactive_count,
      global_active_not_in_alert_count
    } = this.props;
    return (
      <div>
        <span id="alert_links_div">
          <CheckLink global_check_count={global_check_count} check_link={check_link} />
          <CheckNoteLink global_check_note_count={global_check_note_count} check_note_link={check_note_link} />
          <ProblemSensorChart
            global_alert_count_name={global_alert_count_name}
            global_alert_low_count={global_alert_low_count}
            global_alert_high_count={global_alert_high_count}
            global_inactive_count={global_inactive_count}
          />
          <GeneralSensorChart
            global_alert_low_count={global_alert_low_count}
            global_alert_high_count={global_alert_high_count}
            global_active_not_in_alert_count={global_active_not_in_alert_count}
            global_active_count={global_active_count}
            global_inactive_count={global_inactive_count}
          />
        </span>
      </div>
    )
  }
}
export default AlertCountLinks;
