import React, {Component} from 'react';
import styles from './alert_links.module.scss'

class CheckLink extends Component {

  render() {
    const { check_link, global_check_count } = this.props;
    if (global_check_count === 0) {
      return null
    }
    return (
      <a href={check_link} className={'btn btn-primaryLight btn-outline-primary'}>
        Checks <span className={'badge badge-secondary'}>{global_check_count}</span>
      </a>
    )
  }
}

export default CheckLink
