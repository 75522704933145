import React, {Component} from 'react';
import styles from './alert_links.module.scss'

class CheckNoteLink extends Component {

  render() {
    const { check_note_link, global_check_note_count } = this.props;
    if (global_check_note_count === 0) {
      return null
    }
    return (
      <a href={check_note_link} className={'btn btn-primaryLight btn-outline-primary'}>
        Notes <span className={'badge badge-secondary'}>{global_check_note_count}</span>
      </a>
    )
  }
}

export default CheckNoteLink
