import React, {Component} from 'react';
import styles from './alert_links.module.scss'
import * as c3 from "c3";

class ProblemSensorChart extends Component {

  problem_sensors_chart_title() {
    return (this.props.global_alert_low_count + this.props.global_alert_high_count + this.props.global_inactive_count).toString();
  }

  problem_sensors_chart_tooltip() {
    return `${this.props.global_alert_count_name}</br>${this.props.global_inactive_count} Inactive`
  }

  renderChart() {
    let chart_title = this.problem_sensors_chart_title();
    let chart_tooltip = this.problem_sensors_chart_tooltip();
    c3.generate({
      bindto: '#general-problem-chart-wrapper',
      data: {
        type: 'donut',
        columns: [
          ['Active Low Alert', this.props.global_alert_low_count || 0],
          ['Active High Alert', this.props.global_alert_high_count || 0],
          ['Inactive', this.props.global_inactive_count || 0]
        ],
        colors: {
          'Active Low Alert': 'blue',
          'Active High Alert': 'red',
          'Inactive': 'grey'
        },
        onclick: function(d) {
          if (d.id === 'Active High Alert') {
            window.location = '/alerts?filter[reset]=true&filter[open]=2&search=';
          } else if (d.id === 'Active Low Alert') {
            window.location = '/alerts?filter[reset]=true&filter[open]=3&search=';
          } else if (d.id === 'Inactive') {
            window.location = '/sensor_list/inactive?filter[reset]=true&search=';
          }
        },
        order: null,
        onmouseover: tooltip.hide,
        onmouseout: function () { tooltip.show(chart_tooltip, 100); }
      },
      donut: {
        label: { show: false },
        width: 7,
        title: chart_title
      },
      onmouseover: function () { tooltip.show(chart_tooltip, 100); },
      onmouseout: tooltip.hide,
      size: {
        height: 75,
        width: 75
      },
      tooltip: {
        format: { value: function (value) { return value; } }
      },
      legend: { show: false }
    });
  }

  componentDidMount() {
    this.renderChart();
  }

  componentDidUpdate() {
    this.renderChart();
  }

  render() {
    if (this.props.global_alert_low_count + this.props.global_alert_high_count + this.props.global_inactive_count === 0) {
      return null
    }
    return (
      <span className={styles.chart_wrapper}>
        <span>
          <span id="general-problem-chart-wrapper" className={styles.inline_block} />
        </span>
        <br/>
        <span className={styles.chart_title}>
          <strong>Issues</strong>
        </span>
      </span>
    )
  }
}

export default ProblemSensorChart

